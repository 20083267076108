<template>
  <nav class="_nav">
    <section class="_nav-logo">
      <router-link to="/" class="text-decoration">
        <img src="../../assets/img/logopam.png" alt="" class="logo__company" />
      </router-link>
    </section>
    <section class="burger">
      <v-btn icon color="black" @click="down">
        <v-icon size="40">mdi-menu</v-icon>
      </v-btn>
    </section>
    <section class="_nav-menu px-5">
      <div class="close-nav"><v-icon @click="SwipeUp">mdi-close</v-icon></div>
      <router-link to="/" class="menu text-decoration"
        ><p @click="SwipeUp">Home</p></router-link
      >
      <router-link class="menu text-decoration" to="/#service"
        ><p @click="SwipeUp">Service</p></router-link
      >
      <router-link to="/#library" class="menu text-decoration"
        ><p @click="SwipeUp">Library</p></router-link
      >
      <router-link to="/about" class="menu text-decoration"
        ><p @click="SwipeUp">About Us</p></router-link
      >
      <router-link to="/auth/login" class="menu text-decoration">
        <v-btn dark color="blue" class="mb-2">Login</v-btn>
      </router-link>
      <router-link to="/auth/register" class="menu text-decoration">
        <v-btn dark color="blue" class="mb-2">Register</v-btn>
      </router-link>
    </section>
  </nav>
</template>

<script>
export default {
  name: "navbarGeneral",
  methods: {
    down() {
      let menu = document.querySelector("._nav-menu");
      menu.style.top = "0";
    },
    SwipeUp() {
      let menu = document.querySelector("._nav-menu");
      menu.style.top = "-400px";
    }
  }
};
</script>

<style scoped>
.logo__company {
  max-width: 170px;
  height: 70px;
}
.menu {
  cursor: pointer;
  transition: 0.3s;
  color: #000 !important;
}
.menu:hover {
  color: purple !important;
  transform: scale(1.1);
}
</style>
